import { createActions, handleActions } from "redux-actions";
import produce from "immer";
import { createSelector } from "reselect";
import { stopEditing, actionSidebarSelector } from "store/meta";
import apiClient from "utility/apiClient";

function massageObjectForStore(object) {
  const { id, titolo, obj_url, mtl_url, permesso } = object;

  return {
    id,
    title: titolo,
    x: parseFloat(object.cord_x),
    y: parseFloat(object.cord_y),
    z: parseFloat(object.cord_z),
    alpha: parseFloat(object.rot_x),
    beta: parseFloat(object.rot_y),
    gamma: parseFloat(object.rot_z),
    scale: parseFloat(object.scala),

    objUrl: obj_url,
    mtlUrl: mtl_url,

    // add props that are not persisted
    visible: true,
    loading: true,

    permesso,
  };
}

function massageObjectForServer(object) {
  const {
    id,
    title,
    x,
    y,
    z,
    alpha,
    beta,
    gamma,
    objUrl,
    mtlUrl,
    scale,
  } = object;

  return {
    id_obj: id,
    titolo: title,
    cord_x: x,
    cord_y: y,
    cord_z: z,
    rot_x: alpha,
    rot_y: beta,
    rot_z: gamma,
    obj_url: objUrl,
    mtl_url: mtlUrl,
    scala: scale,
  };
}

/* -- actions -- */
export const fetchObjects = ({ rilievoId }) => async dispatch => {
  dispatch(fetchObjectsRequest({ rilievoId }));

  try {
    const objects = await apiClient({ cache: false })({
      action: "get_obj",
      data: {
        id_rilievo: rilievoId,
      },
    });

    dispatch(fetchObjectsSucceeded({ objects, rilievoId }));
  } catch (err) {
    console.log(err);
    dispatch(fetchObjectsFailed(err));
  }
};

export const setObj = ({
  objUrl,
  mtlUrl,
  posUrl,
  rilievoId,
}) => async dispatch => {
  const tempId = new Date().getTime();

  dispatch(createObjectRequest({ objUrl, mtlUrl, posUrl, tempId }));

  try {
    const { id, coords } = await apiClient({})({
      action: "set_obj",
      data: {
        titolo: "New object",
        obj_url: objUrl,
        mtl_url: mtlUrl,
        pos_url: posUrl,
        id_rilievo: rilievoId,
        v2: "1",
      },
    });

    window.objectAction = { type: "set_obj", id, coords };
    dispatch(createObjectSucceeded({ id, tempId, position: coords }));
  } catch (err) {
    // dispatch err action
  }
};

export const { createObjectRequest, createObjectSucceeded } = createActions(
  "CREATE_OBJECT_REQUEST",
  "CREATE_OBJECT_SUCCEEDED"
);

export const { deleteObjectSucceeded, deleteObjectFailed } = createActions(
  "DELETE_OBJECT_SUCCEEDED",
  "DELETE_OBJECT_FAILED"
);

export const {
  fetchObjectsRequest,
  fetchObjectsSucceeded,
  fetchObjectsFailed,
} = createActions({
  FETCH_OBJECTS_REQUEST: undefined,
  FETCH_OBJECTS_SUCCEEDED: ({ objects, rilievoId }) => {
    return {
      objects: objects.map(massageObjectForStore),
    };
  },
  FETCH_OBJECTS_FAILED: err => ({ err }),
});

export const setObjectVisibility = ({ id, visible }) => dispatch =>
  dispatch(updateObjectRequest({ id, name: "visible", value: visible }));

export const { updateObjectRequest, mergeObjectsSettings } = createActions(
  "UPDATE_OBJECT_REQUEST",
  "MERGE_OBJECTS_SETTINGS"
);

export const updateObject = ({ id, name, value }) => async (
  dispatch,
  getState
) => {
  dispatch(updateObjectRequest({ id, name, value }));

  const obj = objectSelectorById(id)(getState());

  if (name === "loading" || name === "progress") {
    return;
  }

  try {
    await apiClient()({
      action: "update_obj",
      data: massageObjectForServer(obj),
    });
    window.objectAction = "reload";
  } catch (err) {
    console.log(err);
  }
};

export const updateWholeObject = obj => async (dispatch, getState) => {
  dispatch(mergeObjectsSettings({ settings: [obj] }));

  const newObj = objectSelectorById(obj.id)(getState());

  try {
    await apiClient()({
      action: "update_obj",
      data: massageObjectForServer(newObj),
    });
  } catch (err) {
    console.log(err);
  }
};

// permissions interface
export const setObjectPermissions = ({ id, permission }) => async () => {
  try {
    await apiClient()({
      action: "permission_obj",
      data: {
        id_obj: id,
        permesso: permission,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

const { deleteObjectRequest } = createActions({
  DELETE_OBJECT_REQUEST: undefined,
});

export const deleteObject = ({ id }) => async (dispatch, getState) => {
  dispatch(deleteObjectRequest({ id }));
  dispatch(updateObjectRequest({ id, name: "deleting", value: true }));

  try {
    await apiClient()({
      action: "remove_obj",
      data: {
        id_obj: id,
      },
    });

    window.objectAction = { type: "delete_obj", id };

    const actionSidebar = actionSidebarSelector(getState());
    // close action sidebar if we are deleting a marker that is currently being edited
    if (
      actionSidebar &&
      actionSidebar.type === "object" &&
      actionSidebar.id === id
    ) {
      dispatch(stopEditing());
    }
    dispatch(deleteObjectSucceeded({ id }));
  } catch (err) {
    console.log(err);
  }
};

/* -- reducers --  */
export const reducer = handleActions(
  {
    [fetchObjectsRequest]: state =>
      produce(state, draft => {
        draft.loading = true;
      }),
    [fetchObjectsSucceeded]: (state, { payload: { objects } }) =>
      produce(state, draft => {
        draft.data = draft.data.concat(objects);
        draft.loading = false;
      }),
    [mergeObjectsSettings]: (state, { payload: { settings } }) =>
      produce(state, draft => {
        draft.data.forEach((object, i) => {
          let mergedObject = settings.find(
            // eslint-disable-next-line eqeqeq
            mergedObject => mergedObject.id == object.id
          );

          if (typeof mergedObject !== "undefined") {
            draft.data[i] = {
              ...object,
              ...mergedObject,
            };
          }
        });
      }),
    [createObjectRequest]: (state, action) =>
      produce(state, draft => {
        const { objUrl, mtlUrl, tempId } = action.payload;

        draft.data.push({
          objUrl,
          mtlUrl,
          tempId,
          title: "New Object",
          x: 0,
          y: 0,
          z: 0,
          alpha: 0,
          beta: 0,
          gamma: 0,
          visible: true,
          loading: true,
          permesso: "2",
          scale: 1,
        });
      }),
    [createObjectSucceeded]: (state, action) =>
      produce(state, draft => {
        const {
          tempId,
          id,
          position: { x, y, z },
        } = action.payload;
        draft.data.forEach((item, i) => {
          if (item.tempId === tempId) {
            draft.data[i].id = id;

            draft.data[i].x = x;
            draft.data[i].y = y;
            draft.data[i].z = z;

            return;
          }
        });
      }),
    [updateObjectRequest]: (state, action) =>
      produce(state, draft => {
        draft.data.forEach((item, i) => {
          if (item.id === action.payload.id) {
            draft.data[i] = objectReducer(item, action);
          }
        });
      }),
    [deleteObjectSucceeded]: (state, action) =>
      produce(state, draft => {
        const { payload } = action;
        const { id } = payload;
        draft.data = draft.data.filter(item => item.id !== id);
      }),
  },
  {
    data: [],
    loading: true,
  }
);

const objectReducer = handleActions(
  {
    [updateObjectRequest]: (state, { payload: { name, value } }) =>
      produce(state, draft => {
        draft[name] = value;
      }),
  },
  {}
);

/* -- selectors -- */
export const objectsLoadingSelector = state => state.objects.loading;

export const objectsSelector = state => state.objects.data;

export const objectsSelectorByRilievoId = rilievoId =>
  createSelector(objectsSelector, objects => objects.filter(object => object));

export const objectSelectorById = id =>
  createSelector(objectsSelector, objects =>
    objects.find(object => object.id == id)
  );
