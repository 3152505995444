import apiClient from "utility/apiClient";

// filtro i nuovi permessi e controllo che siano diversi da quelli esistenti
// ottenendo un array con solo i permessi che sono effettivamente cambiati
function filterPermissions(permissions, newPermissions) {
  if (!newPermissions) return permissions;

  const { applyChildren, allTheRest } = newPermissions.reduce(
    (acc, permission) => {
      if (permission.applyToChildren) {
        acc.applyChildren.push(permission);
      } else {
        acc.allTheRest.push(permission);
      }
      return acc;
    },
    {
      applyChildren: [],
      allTheRest: [],
    }
  );

  const changedPermissions = allTheRest.filter(newPermission => {
    const changedPermission = permissions.find(p => p.id === newPermission.id);

    return (
      changedPermission &&
      changedPermission.permission !== newPermission.permission
    );
  });

  return [changedPermissions, applyChildren];
}

const api = {
  setPermessi: function setPermessi({
    id,
    itemType,
    permissions,
    applyToChildren,
  }) {
    const permessi = Array.isArray(permissions)
      ? permissions
          .map(permission => `${permission.id}:${permission.permission}`)
          .join(",")
      : permissions;

    if (!permessi) return;

    return apiClient()({
      action: "set_permessi_oggetto",
      data: {
        id_oggetto: id,
        tipo_oggetto: itemType,
        permessi,
        cascata: applyToChildren,
      },
    });
  },
  deleteItem: function deleteItem({ id, itemType }) {
    return apiClient()({
      action: "remove_item",
      data: {
        id,
        itemType,
      },
    });
  },
};

export const deleteItem = ({ id, itemType }) => async (dispatch, getState) => {
  try {
    await api.deleteItem({ id, itemType });
  } catch (err) {
    console.log(err);
  }
};

export const setPermissions = ({
  id,
  itemType,
  permissions,
  newPermissions,
  timeline,
}) => async () => {
  const [filteredPermissions, applyChildrenPermissions] = filterPermissions(
    permissions,
    newPermissions
  );

  try {
    await api.setPermessi({
      id,
      itemType,
      permissions: filteredPermissions,
      applyToChildren: 0,
    });
    await api.setPermessi({
      id,
      itemType,
      permissions: applyChildrenPermissions,
      applyToChildren: 1,
    });

    if (timeline) return { filteredPermissions, applyChildrenPermissions };
  } catch (err) {
    console.log(err);
    throw err;
  }
};
