export function fixMeasurement(measurement) {
  const data = JSON.parse(
    measurement.misura.replace(/&amp;quot;/g, '"').replace(/&quot;/g, '"')
  );

  let a = {
    visible: false,
  };

  Object.keys(data).forEach(key => {
    if (data[key] !== null) {
      a[key] = data[key];
    }
  });

  const { visibile, cross_timeline, ..._measurement } = measurement;

  return {
    ..._measurement,
    points: [],
    position: {},
    rotation: {},
    scale: {},
    clip: false,
    visible: true,
    showLabels: visibile === "1",
    crossTimeline: cross_timeline === "1",
    ...a,
  };
}
