import {
  call,
  put,
  takeLatest,
  select,
  delay,
  debounce,
} from "redux-saga/effects";
import deepEqual from "deep-equal";

import * as api from "./api";
import * as actions from "./actions";
import * as selectors from "./selectors";

function massage(measurement) {
  return {
    ...measurement,
    crossTimeline: measurement.cross_timeline === "1",
  };
}

function* fetchMeasurements(action) {
  yield put(actions.fetchMeasurementsRequest(action.payload.rilievoId));

  try {
    const measurements = yield call(api.getMisure, action.payload.rilievoId);
    yield put(
      actions.fetchMeasurementsSucceeded({
        measurements: measurements.map(massage),
      })
    );
  } catch (err) {
    console.log("fetch failed ", err);
    yield put(actions.fetchMeasurementsFailed({ err }));
  }
}

function* createMeasurement(action) {
  const {
    rilievoId,
    measure,
    measurement: { type, ...props },
  } = action.payload;

  // yield put(
  //   actions.createMeasurementRequest({
  //     type,
  //     points: measure.points,
  //     ...props,
  //   })
  // );

  const { points = [], scale, position, rotation } = measure;

  try {
    const id = yield call(api.createMisura, rilievoId, type, {
      ...props,
      points,
      scale,
      position,
      rotation,
    });

    yield put(
      actions.createMeasurementSucceeded({
        id: `${id}`,
        titolo: "Misura",
        tipo: type,
        ...props,
        points,
        scale,
        position,
        rotation,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(actions.createMeasurementFailed(err));
  }
}

function* updateMeasurement(action) {
  const { id, tempId, merge } = action.payload;

  const measurement = yield select(
    selectors.measurementSelectorByIdOrTempId(id || tempId)
  );

  if (typeof merge.points !== "undefined") {
    const cleanedUpPoints = measurement.points.map(point => ({
      position: point.position,
    }));

    if (JSON.stringify(merge.points) === JSON.stringify(cleanedUpPoints)) {
      return null;
    }
  }

  yield put(
    actions.updateMeasurementRequest({
      id,
      tempId,
      merge,
    })
  );

  try {
    yield delay(1000);

    const newMeasurement = yield select(
      selectors.measurementSelectorByIdOrTempId(id || tempId)
    );
    yield call(api.updateMisura, newMeasurement);

    if (!newMeasurement.imported) {
      // don't try to save imported measurements
      yield put(actions.updateMeasurementSucceeded({ id }));
    }
  } catch (err) {
    yield put(actions.updateMeasurementFailed(err));
  }
}

function* showProfileWindow(action) {
  const { id } = action.payload;

  const viewer = window.viewer;

  const {
    scene: { profiles, pointclouds },
  } = viewer;

  const profile = profiles.find(profile => profile.userData.id === id);

  yield call(() => viewer.profileWindow.show());
  yield call(() => viewer.profileWindowController.setProfile(profile));

  yield call(() => {
    setTimeout(() => {
      const firstPointcloud =
        pointclouds &&
        pointclouds.length > 0 &&
        pointclouds.reduce((visibleCloud, cloud) => {
          if (visibleCloud) {
            return visibleCloud;
          }

          if (cloud.visible) {
            return cloud;
          }
        }, null);
      const initialPCT = firstPointcloud.material.pointColorType;

      firstPointcloud.material.pointColorType = 2;
      firstPointcloud.material.pointColorType = initialPCT;
    }, 750);
  });
}

function* generateExportUrls() {
  if (window.viewer) return;
  const { measurements } = window.viewer.scene;

  let DXFUrl = null;
  let GeoJSONUrl = null;

  if (measurements.length === 0) return;

  const DXFString = yield call(
    window.Potree.DXFExporter.toString,
    measurements
  );
  const GeoJSONString = yield call(
    window.Potree.GeoJSONExporter.toString,
    measurements
  );

  DXFUrl = yield call(
    window.URL.createObjectURL,
    new Blob([DXFString], { type: "data:application/octet-stream" })
  );
  GeoJSONUrl = yield call(
    window.URL.createObjectURL,
    new Blob([GeoJSONString], { type: "data:application/octet-stream" })
  );

  yield put(actions.setExportLinks({ DXFUrl, GeoJSONUrl }));
}

function* rootSaga() {
  yield takeLatest("FETCH_MEASUREMENTS", fetchMeasurements);
  yield takeLatest("CREATE_MEASUREMENT", createMeasurement);
  yield takeLatest("UPDATE_MEASUREMENT", updateMeasurement);
  yield takeLatest("SHOW_PROFILE_WINDOW", showProfileWindow);

  yield debounce(
    1000,
    action => /MEASUREMENT/.test(action.type),
    generateExportUrls
  );
}

export default rootSaga;
