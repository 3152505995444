import { createActions, handleActions, combineActions } from "redux-actions";
import { createSelector } from "reselect";
import produce from "immer";
import { compareDesc } from "date-fns";

import apiClient from "utility/apiClient";

import POINTCOLORS from "const/pointColors";

import { actionSidebarSelector } from "./meta";

import { rilievoSelectorById } from "./rilievi";

/** -- actions -- **/
export const { addCloud } = createActions({
  ADD_CLOUD: undefined,
});

export const { mergeCloudsSettings } = createActions({
  MERGE_CLOUDS_SETTINGS: undefined,
});

const {
  fetchCloudsRequested,
  fetchCloudsSucceeded,
  fetchCloudsFailed,
} = createActions(
  "FETCH_CLOUDS_REQUESTED",
  "FETCH_CLOUDS_SUCCEEDED",
  "FETCH_CLOUDS_FAILED"
);

export const fetchClouds = ({ rilievoId }) => async (dispatch, getState) => {
  dispatch(fetchCloudsRequested({ rilievoId }));
  const rilievo = rilievoSelectorById(rilievoId)(getState());

  try {
    const timelineId = rilievo.id_timeline;

    const clouds = await apiClient({})({
      action: "get_rilievi_timeline",
      data: {
        id_timeline: timelineId,
      },
    }).then(clouds => {
      return clouds.map(cloud => ({
        ...cloud,
        cloudType: cloud.tipo_nuvola,
        permesso: parseInt(cloud.permesso),
      }));
    });

    dispatch(fetchCloudsSucceeded({ clouds, rilievoId }));
  } catch (err) {
    console.log(err);
    dispatch(fetchCloudsFailed(err));
  }
};

const {
  updateCloudRequest,
  updateCloudSucceeded,
  updateCloudFailed,
} = createActions(
  "UPDATE_CLOUD_REQUEST",
  "UPDATE_CLOUD_SUCCEEDED",
  "UPDATE_CLOUD_FAILED"
);

export const updateCloud = ({ id, name, value }) => async (
  dispatch,
  getState
) => {
  dispatch(updateCloudRequest({ id, name, value }));

  const cloud = cloudSelectorById(id)(getState());

  try {
    await apiClient()({
      action: "update_rilievo",
      data: {
        id_rilievo: id,
        padre: cloud.padre,
        titolo: cloud.titolo,
        url_nuvola: cloud.url,
        tipo_nuvola: cloud.cloudType,
      },
    });
    window.cloudAction = "reload";
    dispatch(updateCloudSucceeded({ id, name, value }));
  } catch (err) {
    dispatch(updateCloudFailed(err));
  }
};

/** -- reducers -- **/
export const reducer = handleActions(
  {
    [combineActions(fetchCloudsSucceeded)]: (state, action) =>
      produce(state, draft => {
        const {
          payload: { clouds, rilievoId },
        } = action;

        draft.loading = false;
        draft.data = clouds
          .sort((cloudA, cloudB) =>
            compareDesc(cloudA.acquisizione, cloudB.acquisizione)
          )
          .map((cloud, i) =>
            cloudReducer(
              undefined,
              addCloud({
                ...cloud,
                points: parseInt(cloud.punti, 10),
                visible: cloud.id === rilievoId,
              })
            )
          );
      }),
    [mergeCloudsSettings]: (state, { payload: { settings } }) => {
      return {
        ...state,
        data: state.data.map(cloud => {
          // find id in settings
          let mergedCloud = settings.find(
            // eslint-disable-next-line eqeqeq
            mergedCloud => mergedCloud.id == cloud.id
          );

          if (typeof mergedCloud !== "undefined") {
            return {
              ...cloud,
              ...mergedCloud,
            };
          } else {
            return cloud;
          }
        }),
      };
    },
    [updateCloudRequest]: (state, action) => {
      const {
        payload: { id },
      } = action;

      return {
        ...state,
        data: state.data.map(cloud =>
          cloud.id === id ? cloudReducer(cloud, action) : cloud
        ),
      };
    },
  },
  {
    data: [],
    loading: true,
  }
);

export const cloudReducer = handleActions(
  {
    [mergeCloudsSettings]: (state, { payload: { ...settings } }) => {
      return {
        ...state,
        ...settings,
      };
    },
    [updateCloudRequest]: (state, { payload: { name, value } }) => {
      return {
        ...state,
        [name]: value,
      };
    },
    [addCloud]: (state, { payload: { ...cloud } }) => {
      return {
        ...state,
        ...cloud,
        url:
          `${cloud.url}` || `${process.env.REACT_APP_INC}/rilievi/${cloud.id}`,
      };
    },
  },
  {
    visible: false,
    pointSizeType: "FIXED",
    pointSize: 3,
    pointShape: "CIRCLE",
    pointColorType: 0,
    pointColor: POINTCOLORS[0].value,
    pointOpacity: 1,
    rgbGamma: 1,
    rgbBrightness: 0,
    rgbContrast: 0,
    gradient: "PLASMA",
    cloudType: "0",
  }
);

/** -- selectors -- **/
export const cloudsLoadingSelector = state => state.clouds.loading;

export const cloudsSelector = state => state.clouds.data;

export const visibleCloudsSelector = createSelector(cloudsSelector, clouds =>
  clouds.filter(cloud => cloud.visible)
);

export const cloudSelectorById = cloudId =>
  createSelector(cloudsSelector, clouds =>
    clouds.find(cloud => cloud.id === cloudId)
  );

export const getMaxVisiblePoints = createSelector(cloudsSelector, clouds =>
  clouds.reduce((acc, cloud) => acc + cloud.points, 0)
);

export const getCloudBeingEdited = createSelector(
  cloudsSelector,
  actionSidebarSelector,
  (state, actionSidebar) => {
    if (!actionSidebar) return null;

    return state.find(x => x.id === actionSidebar.id);
  }
);
