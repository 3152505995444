import React from "react";
import styled, { css } from "styled-components";
import { transparentize } from "polished";

import "styled-components/macro";

import Spinner from "./Spinner";
import Btn from "./Btn";

const Sidekick = ({ visible = true, doubleSize, collapsedNav, ...props }) => {
  const { children, ...otherProps } = props;

  const hasChildren =
    React.isValidElement(children) ||
    (children && children.filter(x => x).length > 0);

  return (
    <_Sidekick
      {...otherProps}
      doubleSize={doubleSize}
      collapsedNav={collapsedNav}
      visible={hasChildren && visible}
    >
      {children}
    </_Sidekick>
  );
};

const _Sidekick = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 100;

  /* transition: all 0.4s cubic-bezier(0, 1.08, 0, 1); */
  background-color: ${props => props.theme.semitransparentBlack};

  display: none;

  ${props =>
    props.visible &&
    css`
      display: block;
    `}

  ${props =>
    !props.right &&
    css`
      left: ${props.collapsedNav ? "52px" : "12rem"};
    `}

  ${props =>
    props.right &&
    css`
      right: 0px;
      background-color: rgba(20, 20, 20, 1);
    `}

  font-family: sans-serif;

  color: #ccc;

  width: ${props => (props.doubleSize ? "calc(50vw - 12rem)" : "300px")};
  overflow-y: auto;

  @media screen and (max-width: 400px) {
    width: ${props => (props.right ? "100vw" : `calc(100vw - 52px)`)};
    z-index: ${props => props.right && 200};
  }
`;

Sidekick.Header = styled.div`
  padding-bottom: 0;
  margin-bottom: 1.5rem;
  position: relative;
`;

Sidekick.Super = styled.div`
  padding: 0.5rem;
  background-color: #110f12;
  color: #ccc;
  font-size: 11px;

  display: flex;

  justify-content: space-between;
  align-items: center;

  text-transform: uppercase;
  font-weight: bold;
`;

Sidekick.SuperFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: #222;
`;

Sidekick.Tabs = styled.div`
  color: white;
  padding: 1rem;
  display: flex;
  align-items: flex-end;

  font-size: 1rem;

  > * {
    margin-right: 1rem;
  }
`;

Sidekick.Toolbar = styled.div`
  display: flex;
  align-items: center;

  ${props =>
    props.floated &&
    css`
      position: absolute;
      top: 50%;
      right: 0;

      transform: translateY(-50%);
    `} > * {
    margin-left: 0.75rem;
  }

  transition: all 0.2s ease-in;
  /* opacity: 0.25; */
`;

Sidekick.Title = styled.h3`
  font-family: "Titillium Web", sans-serif;
  line-height: 1;
  margin: 0;
  margin-bottom: 1em;
  position: relative;
  font-weight: 400;
  font-size: 1.25rem;

  color: #fff;

  ${props =>
    props.small &&
    css`
      font-size: 14px;
    `} &:hover {
    ${Sidekick.Toolbar} {
      opacity: 1;
    }
  }
`;

Sidekick.SmallTitle = styled(Sidekick.Title)`
  color: #aaa;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1rem;
  margin-bottom: ${props => (props.noMargin ? "0px" : "1rem")};
  margin-top: ${props => (props.noMargin ? "0px" : "2rem")};

  &:first-child {
    margin-top: 0;
  }
`;

Sidekick.Block = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #232223;

  ${props =>
    props.squared &&
    css`
      border-radius: 4px;
      margin-left: 0;
      margin-right: 0;
      padding: 1rem;
      background-color: rgba(255, 255, 255, 0.1);
    `};

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 6rem;
    border-bottom: none;
  }
`;

Sidekick.Group = styled.div`
  margin-bottom: 1rem;
`;

Sidekick.Label = styled.div.attrs(props => {
  let fontSize = 13;
  let marginBottom = 0.5;

  if (props.size === "small") fontSize = 11;
  if (props.size === "big") fontSize = 16;
  if (props.noMarginBottom) marginBottom = 0;

  return {
    fontSize,
    marginBottom,
  };
})`
  font-size: ${props => props.fontSize}px;
  font-family: "Titillium Web", sans-serif;
  margin-bottom: ${`${props => props.marginBottom}px`};
  cursor: ${props => (props.noPointer ? "initial" : "pointer")};
  ${props =>
    props.hoverUnderline &&
    css`
      :hover {
        text-decoration: underline;
      }
    `}
`;

Sidekick.Accordion = function Accordion({
  title,
  isOpen = false,
  accordion,
  children,
  buttons,
}) {
  const [open, setOpen] = React.useState(isOpen);

  return (
    <>
      {title && (
        <Sidekick.SmallTitle>
          {title}

          <Sidekick.Toolbar floated={1}>
            {React.isValidElement(buttons) && buttons}

            <Btn
              icon={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
              circle
              onClick={() => {
                setOpen(!open);
              }}
            />
          </Sidekick.Toolbar>
        </Sidekick.SmallTitle>
      )}
      {open && children}
    </>
  );
};

Sidekick.Section = styled.div`
  margin-bottom: 1.5rem;
  position: relative;

  p {
    font-size: 14px;
    line-height: 1.6;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${Sidekick.Label} {
    margin-bottom: 0.25rem;
  }

  ${props =>
    props.vertical &&
    css`
      & > div {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;
        margin-bottom: 0.5rem;

        ${Sidekick.Label} {
          margin-bottom: 0;
        }
      }
    `}

  ${props =>
    props.indented &&
    css`
      padding: 1rem;
      background-color: #222;
      border-radius: 4px;
    `};
`;

Sidekick.ScrollContainer = styled.div`
  overflow-x: scroll;
  padding-bottom: 1rem;

  > * {
    margin-right: 2rem;
  }
`;

Sidekick.Table = styled.table`
  font-size: 12px;
  width: 100%;
  overflow-x: scroll;

  thead {
    tr {
      border-bottom: 1px solid red;
    }
  }

  th {
    font-weight: bold;
  }

  td,
  th {
    text-align: left;
    padding: 0.5em;
  }
`;

Sidekick.Input = styled.div.attrs(props => {
  let fontSize = 1;

  if (props.size === "small") fontSize = 0.75;
  if (props.size === "big") fontSize = 1.25;

  return {
    fontSize,
  };
})`
  font-size: ${props => props.fontSize}rem;

  input,
  select {
    font-size: 1em;
    box-sizing: border-box;
    width: 100%;
    background-color: #110f12;
    border: none;
    outline: none;
    border: 1px solid #111;
    padding: 0.5em 0.75em;
    color: #ddd;
    border-radius: 3px;

    &::placeholder {
      color: #232223;
    }

    box-shadow: 0 0 0 5px ${props => transparentize(1, props.theme.high)};
    transition: all 0.3s ease;

    &:focus {
      box-shadow: 0 0 0 2px ${props => props.theme.high};
    }

    &:disabled {
      background-color: transparent;
      border-radius: 0;
      border-color: transparent;
      border-bottom-color: #232223;
      padding-left: 0;
      color: #fff;
    }
  }

  select {
    height: 2.5rem;
  }
`;

function _Card(props) {
  const style = props.style;
  if (style) {
    if (isNaN(style.height)) style.height = "auto";
    if (isNaN(style.left)) style.left = "auto";
    if (isNaN(style.top)) style.top = "auto";
  }

  return (
    <div
      className={props.className}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      style={style}
    >
      {props.children}
    </div>
  );
}

Sidekick.Card = styled(_Card)`
  position: relative;
  overflow: hidden;
  background-color: #110f12;
  min-height: 54px;

  display: flex;
  border-radius: 10px;
  cursor: pointer;

  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &:active {
    background-color: #000 !important;
  }

  user-select: none;

  &:hover {
    background-color: #000;
    opacity: 1 !important;
  }

  transition: opacity 0.12s ease-in-out;

  box-shadow: inset 0 0 0 2px
      ${props => (props.active ? props.theme.high : "transparent")},
    rgba(14, 21, 47, 0.3) 0px 8px 17px;

  opacity: ${props => (props.visible ? 1 : 0.4)};
`;

Sidekick.CardPlaceholder = styled.div`
  width: 100%;
  height: 52px;
`;

Sidekick.CardWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

Sidekick.CardSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 0.5rem 0;

  & + * {
    margin-left: 1rem;
  }

  &:first-child {
    margin-left: 1rem;
  }

  &:last-child {
    margin-right: 1rem;
  }

  ${props =>
    props.showOnHover &&
    css`
      opacity: 0.05;
      transition: all 0.3s ease;

      ${Sidekick.Card}:hover & {
        opacity: 1;
      }
    `}

  ${props =>
    props.fullBleed &&
    css`
      margin-left: 0 !important;
      padding: 0;
    `}

  ${props =>
    props.full &&
    css`
      flex: 100% 1 1;
    `};
`;

Sidekick.CardToggleContainer = styled.div`
  width: 1rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

Sidekick.CardToggle = styled.div`
  width: 1rem;
  height: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    width: 8px;
    height: 8px;
    transition: all 0.3s ease;
    background-color: ${props => (props.active ? props.theme.high : "#333")};
    border-radius: 100%;

    box-shadow: 0 0 0 2px transparent;
  }

  &:hover {
    &:after {
      box-shadow: 0 0 0 2px ${props => props.theme.high};
    }
  }
`;

Sidekick.CardTitle = styled.div`
  font-size: 14px;
  line-height: 1.125;
  font-family: "Titillium Web", sans-serif;
`;

Sidekick.CardSubtitle = styled.div`
  font-size: 12px;

  display: flex;
  align-items: center;

  margin-top: 3px;
  color: #999;

  > * + * {
    margin-left: 0.5em;
    align-items: center;
  }
`;

Sidekick.CardLoading = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

Sidekick.Error = styled.div`
  color: white;
  font-size: 11px;
  background-color: ${props => props.theme.red};
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.25rem;
`;

Sidekick.Box = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  color: #ccc;
  padding: 2rem;

  > * + * {
    margin-top: 1rem;
  }
`;

Sidekick.Empty = styled(Sidekick.Box)`
  border: 2px dashed #232223;
`;

Sidekick.Loading = styled(props => {
  return (
    <div {...props}>
      <Spinner />
    </div>
  );
})`
  ${css`
    width: 100%;
    height: ${props => (props.small ? "80px" : "160px")};
  `};
`;

Sidekick.Delete = styled.div`
  color: #999;
  text-decoration: underline;
  line-height: 1.125em;
  font-size: 10px;

  &:hover {
    color: ${props => props.theme.red};
  }
`;

Sidekick.Float = styled.div`
  margin-left: auto;
  margin-right: 0;
`;

Sidekick.DataCard = styled.div`
  background-color: #232223;
  border-radius: 3px;
`;

Sidekick.DataList = styled.div``;
Sidekick.DataListItem = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #232223;
`;

Sidekick.DataCardCommands = styled.div`
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${Sidekick.DataListItem}:hover & {
    opacity: 1;
  }
`;

Sidekick.PullRight = styled.div`
  margin-left: auto;
  margin-right: 0;
`;

Sidekick.Sidebar = styled.div`
  padding: 1rem;
  height: 100%;
  position: relative;

  ${Sidekick.SmallTitle} {
    margin-bottom: 1.5rem;
  }
`;

Sidekick.Text = styled.div`
  font-size: ${props => props.fontSize}px;
  font-family: "Titillium Web", sans-serif;
  margin: 1rem 0;
  text-align: ${props => (props.alignCenter ? "center" : "initial")};
`;

export default Sidekick;
