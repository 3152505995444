import { createAction, handleActions } from "redux-actions";
import { createSelector } from "reselect";

import { editItem, openSurveyInNewWindow, stopEditing } from "../meta";
import { fetchMarkerData, markerDataSelectorByMarkerId } from "../markerData";
import { markerSelectorById } from ".";

/* -- action creators -- */
export const setMarkerHover = createAction("SET_MARKER_HOVER");
export const unsetMarkerHover = createAction("UNSET_MARKER_HOVER");
export const setMarkerEdited = createAction("SET_MARKER_EDITED");
export const showTooltip = createAction("SHOW_TOOLTIP");
export const hideTooltip = createAction("HIDE_TOOLTIP");

export const editMarker = (id, disableDefault = false) => async (
  dispatch,
  getState
) => {
  await dispatch(fetchMarkerData(id));
  const marker = markerSelectorById(id)(getState());
  const markerData = markerDataSelectorByMarkerId(id)(getState());

  if (!marker) return;

  if (marker.type === "3" && marker.link) {
    dispatch(openSurveyInNewWindow(marker.link));
    return;
  } else if (marker.type === "9") {
    dispatch(_editMarker(id));
    return;
    if (markerData && markerData.length > 0) {
      const { valore } = markerData[0];
      if (valore) {
        window.open(
          `https://primis-sfera.netlify.com/pano/${valore}`,
          "_blank"
        );
        return;
      }
    }
  }

  if (disableDefault) {
    dispatch(stopEditing());
  } else {
    dispatch(_editMarker(id));
  }
};

const _editMarker = id => dispatch => {
  dispatch(
    editItem({
      id,
      type: "marker",
    })
  );
  dispatch(setMarkerEdited({ markerId: id }));
  dispatch(unsetMarkerHover());
};

/* -- reducer -- */
export const reducer = handleActions(
  {
    [setMarkerHover]: (state, action) => {
      const { markerId } = action.payload;

      return {
        ...state,
        hoveredMarker: markerId || null,
      };
    },
    [unsetMarkerHover]: (state, action) => {
      return {
        ...state,
        hoveredMarker: null,
      };
    },
    [showTooltip]: (state, action) => {
      return {
        ...state,
        showTooltip: true,
      };
    },
    [hideTooltip]: (state, action) => {
      return {
        ...state,
        showTooltip: false,
      };
    },
    [setMarkerEdited]: (state, action) => {
      const { markerId } = action.payload;

      return {
        ...state,
        editedMarker: markerId || null,
      };
    },
  },
  {
    hoveredMarker: null,
    editedMarker: null,
    showTooltip: false,
  }
);

/* -- selectors -- */
const metaSelector = state => state.meta;

export const _hoveredMarkerSelector = createSelector(
  metaSelector,
  state => state.markers.hoveredMarker
);

export const showTooltipSelector = createSelector(
  metaSelector,
  state => state.markers.showTooltip
);

export const hoveredMarkerSelector = markerId =>
  createSelector(_hoveredMarkerSelector, hoveredMarker => {
    return hoveredMarker === markerId;
  });

export const editedMarkerSelector = markerId =>
  createSelector(
    metaSelector,
    state => state.markers.editedMarker === markerId
  );
